
import { defineComponent, ref, onMounted, onBeforeUnmount, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import ApiService from "@/core/services/ApiService";
import { useI18n } from "vue-i18n";
import JwtService from "@/core/services/JwtService";
import { Check, Delete, InfoFilled } from "@element-plus/icons-vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { event } from "vue-gtag";
import { useLeaveConfirmation } from "@/costumComponents/useLeaveConfirmation";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

interface NewAddressData {
  matiere: string;
  trimestre: string;
}
interface IStudent {
  _id: string;
  BirthDate: string;
  firstName: string;
  lastName: string;
  gender: string;
}

interface VMark {
  _id: string;
  type: string;
  mark: number;
  schoolarYear: string;
  student: string;
  subject: string;
  subsubject: string;
  trimester: number;
  divided: boolean;
  classroom?: string;
}

interface VMarkData {
  student: string;
  subject: string;
  subsubject?: string;
  mark: number;
  divided: boolean;
  trimester?: number;
  classroom?: string;
  type?: string;
}
interface FromModule {
  type: string;
  name: string;
}

export default defineComponent({
  name: "Marksgeneral",
  components: {},
  props: {
    widgetClasses: String,
    type: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const studentList = ref<IStudent[]>([]);
    const cacheStudentList = ref<IStudent[]>([]);

    const apiUrl = ref<string>(store.getters.serverConfigUrl.base_url + "/");

    const sy = window.localStorage.getItem("activeSchoolarYear");
    const trimester = JwtService.getTrimester();
    const loading = ref(true);

    const targetData = ref<NewAddressData>({
      matiere: "",
      trimestre: trimester || "1",
    });
    const fromModule = ref<FromModule>({
      type: "",
      name: "",
    });
    const { setUnsavedChanges, navigationGuard } = useLeaveConfirmation();

    onMounted(async () => {
      //GET STUDENT,
      ApiService.setHeader();
      let match = {};
      match[`schoolarYearsHistory.${sy}`] = route.params.id;
      await ApiService.post("/students/filter", {
        query: { status: "active" },
        aggregation: [
          {
            $match: match,
          },
          {
            $set: {
              classRoom: {
                $convert: {
                  input: "$schoolarYearsHistory." + sy,
                  to: "objectId",
                  onError: null,
                  onNull: null,
                },
              },
            },
          },
          {
            $lookup: {
              from: "classrooms",
              localField: "classRoom",
              foreignField: "_id",
              as: "classRoom",
            },
          },
          {
            $project: {
              _id: 1,
              classRoom: { $arrayElemAt: ["$classRoom._id", 0] },
              classRoomName: {
                $arrayElemAt: ["$classRoom.name", 0],
              },
              teachers: {
                $arrayElemAt: ["$classRoom.teachers", 0],
              },
              BirthDate: 1,
              firstName: 1,
              lastName: 1,
              gender: 1,
              photo: 1,
              firstNameLower: { $toLower: "$firstName" },
            },
          },
          {
            $sort: {
              firstNameLower: 1,
            },
          },
        ],
      }).then(({ data }) => {
        data.map((student) => {
          student.fullName = student.firstName + " " + student.lastName;
        });
        studentList.value = data;
        cacheStudentList.value = data;
        loading.value = false;
      });

      //GET TEACHER
      ApiService.setHeader();
      ApiService.post("/teacher/classRommDetails", {
        query: {
          teacher: store.getters.currentUser._id,
          trimester: targetData.value.trimestre,
          classroom: route.params.id,
        },
      })
        .then(({ data }) => {
          let listSubject: any[] = [];
          const classroom = data[0];
          if (classroom) {
            listSubject = classroom.subjects;
            //remove duplicated
            listSubject = listSubject.filter(
              (subject, index) =>
                listSubject.findIndex((s) => s._id == subject._id) === index &&
                subject.status !== "inactive"
            );
            subjectList.value = listSubject;
            currentLevel.value = classroom.classRoom.level;
            getModules(classroom.classRoom.level, listSubject).finally(() => {
              loading.value = false;
            });
          }
        })
        .catch((e) => console.log(e));
      router.beforeEach(navigationGuard);
    });

    //used when change module type to get new modules
    const currentLevel = ref<number>(0);
    const subjectList = ref<any>([]);

    const markData = ref<VMarkData[]>([]);
    const originalMarkData = ref<VMark[]>([]);
    const listModules = ref<any[]>([]);
    const selectedSubject = ref<string>("");
    const selectedSubjectName = ref<string>("");
    const activeModule = ref<any>(null);
    const subsubjectData = ref<any[]>([]);
    const divided = ref<boolean>(false);
    const disabled = ref<boolean>(false);
    const disabledAccess = ref(true);

    const updateMarks = async () => {
      const currentMonth = new Date().getMonth();

      disabledAccess.value = true;

      if (targetData.value.trimestre == "1") {
        if (currentMonth >= 8 && currentMonth <= 11)
          disabledAccess.value = false;
      } else if (targetData.value.trimestre == "2") {
        if (currentMonth >= 1 && currentMonth <= 3)
          disabledAccess.value = false;
      } else if (targetData.value.trimestre == "3") {
        if (currentMonth >= 3 && currentMonth <= 5)
          disabledAccess.value = false;
      }

      loading.value = true;
      await ApiService.post("/bulletin/v2/mark/filter", {
        query: {
          classroom: route.params.id,
          trimester: targetData.value.trimestre,
          type: {
            $in: ["one"],
          },
        },
      })
        .then(({ data }) => {
          markData.value = [];
          originalMarkData.value = [];
          originalMarkData.value = data;
          data.map((mark: VMark) => {
            markData.value.push({
              student: mark.student,
              subject: mark.subject,
              subsubject: mark.subsubject,
              mark: mark.mark,
              divided: mark.divided,
            });
          });
        })
        .finally(() => (loading.value = false));
    };
    const getTypeName = (typeKey: string) => {
      switch (typeKey) {
        case "MT":
          return t("classInfo.markPilote");
        case "MS":
          return t("classInfo.markSpecific");

        case "MP":
          return t("classInfo.markPeda");
        default:
          return "";
      }
    };
    const title = ref<string>(t("classInfo.markPilote"));
    const getModules = async (level: number, listSubject: any[]) => {
      let moduleType = "MP";
      switch (route.params.type) {
        case "pilote":
          moduleType = "MT";
          title.value = t("classInfo.markPilote");
          setCurrentPageBreadcrumbs(t("classInfo.markPilote"), ["Classe"]);
          break;
        case "spec":
          moduleType = "MS";
          title.value = t("classInfo.markSpecific");

          setCurrentPageBreadcrumbs(t("classInfo.markSpecific"), ["Classe"]);

          break;
        default:
          moduleType = "MP";
          title.value = t("classInfo.markPeda");
          setCurrentPageBreadcrumbs(t("classInfo.markPeda"), ["Classe"]);

          break;
      }
      await ApiService.post("/bulletin/v2/module/filter/marks", {
        query: {
          status: "active",
          level: level,
          trimester: targetData.value.trimestre,
          type: moduleType,
        },
      })
        .then((res) => {
          res.data.map((module) => {
            module.subjects = module.subjects.filter((s) =>
              listSubject.find((ls) => ls._id == s._id)
            );
          });

          listModules.value = res.data;
        })
        .catch((e) => {
          console.log(e);
        });
    };

    const handleSubjectChange = () => {
      selectedSubject.value = targetData.value.matiere;

      listModules.value.forEach((module) => {
        module.subjects.forEach((subject) => {
          if (subject._id == targetData.value.matiere) {
            selectedSubjectName.value = subject.name;
            activeModule.value = module;
          }
        });
      });

      subsubjectData.value = [];

      const subsubjects =
        activeModule.value.subjectsInfo[selectedSubject.value];
      const selectedSubjectData = activeModule.value.subjects.find(
        (s) => String(s._id) == selectedSubject.value
      );
      disabled.value = selectedSubjectData
        ? selectedSubjectData.disabled
        : false;
      if (selectedSubjectData.disabled)
        fromModule.value = {
          type: getTypeName(selectedSubjectData.fromModule.type),
          name: selectedSubjectData.fromModule.name || "",
        };
      if (subsubjects && subsubjects.subsubjectsMark) {
        divided.value = true;
        for (const sub of Object.keys(subsubjects.subsubjectsMark)) {
          const s = activeModule.value.subsubjects.find(
            (s) => String(s._id) == sub
          );
          subsubjectData.value.push({
            name: s.name,
            _id: s._id,
            divided: true,
            maxMark: subsubjects.subsubjectsMark[sub],
          });
        }
      } else {
        divided.value = false;
      }

      updateMarks();
    };

    const getMarkValue = (id, studentId, divided) => {
      const mark = markData.value.find(
        (m) =>
          m.student == studentId &&
          m.divided == divided &&
          (m.subsubject == id || m.subject == id)
      );
      if (mark) return mark.mark;
      return undefined;
    };

    const getMarkCssClasses = (id, studentId, divided, maxMark = 20) => {
      const mark = getMarkValue(id, studentId, divided);
      if (mark || mark === 0) {
        if (isNaN(mark) || Number(mark) > maxMark || Number(mark) < 0)
          return "el-form el-form-item is-error";
      }
      return "";
    };

    const getTotalSubsubjects = (studentId: string) => {
      const subsubjects = subsubjectData.value.map((s) => String(s._id));
      const marks = markData.value.filter((m) => {
        if (m.subsubject)
          return (
            m.student == studentId &&
            m.divided &&
            subsubjects.includes(m.subsubject)
          );
      });

      let total = 0;
      marks.forEach((mark) => {
        total += mark.mark;
      });
      let sumMax = getSumMaxMArks(subsubjectData.value);
      return total ? ((total * 20) / sumMax).toFixed(2) : "";
    };
    const getSumMaxMArks = (subsubjectData) => {
      let sumMax = 0;
      subsubjectData.forEach((sub) => {
        sumMax += sub.maxMark;
      });
      return sumMax;
    };
    const deleteMark = (id: string, studentId: string, divided: boolean) => {
      markData.value = markData.value.filter(
        (m) =>
          !(
            m.student == studentId &&
            m.divided == divided &&
            (m.subsubject == id || m.subject == id)
          )
      );
      setUnsavedChanges(true);
    };

    const handleMarkChange = (
      newMark,
      subjectId,
      subSubjectId,
      studentId,
      divided
    ) => {
      const mark = markData.value.find(
        (m) =>
          m.student == studentId &&
          ((divided && m.subsubject == subSubjectId) ||
            (!divided && m.subject == subjectId))
      );
      if (mark) mark.mark = Number(newMark);
      else {
        const obj: VMarkData = {
          student: studentId,
          mark: Number(newMark),
          divided: divided,
          subject: subjectId,
        };
        if (divided) obj.subsubject = subSubjectId;

        markData.value.push(obj);
      }
      setUnsavedChanges(true);
    };

    const saveLoading = ref(false);

    const saveAll = async () => {
      event("Save all specific marks", {
        event_category: "Marks specific",
        event_label: "Classe profile",
        value: 1,
      });

      saveLoading.value = true;

      // check if subsubject marks are completed
      if (divided.value) {
        const subsubjects = subsubjectData.value.map((s) => String(s._id));
        for (const student of studentList.value) {
          const marks = markData.value.filter((m) => {
            return (
              m.student == student._id &&
              m.divided &&
              subsubjects.includes(m.subsubject as string)
            );
          });

          if (marks.length != 0 && marks.length != subsubjects.length) {
            saveLoading.value = false;
            return Swal.fire({
              text: t("notes.missedSubsubjects", {
                name: student.firstName + student.lastName,
              }),
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
          }
        }
      }

      // check if marks are correct
      let thereError = false;
      for (const mark of markData.value) {
        if (!mark.divided) {
          if (isNaN(Number(mark.mark)) || mark.mark > 20 || mark.mark < 0) {
            thereError = true;
            break;
          }
        } else if (mark.divided) {
          const subsubject = subsubjectData.value.find(
            (s) => s._id == mark.subsubject
          );
          if (subsubject)
            if (
              isNaN(Number(mark.mark)) ||
              mark.mark > subsubject.maxMark ||
              mark.mark < 0
            ) {
              thereError = true;
              break;
            }
        }
      }

      if (thereError) {
        Swal.fire({
          text: t("notes.notCorrectMarks"),
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        saveLoading.value = false;
        return;
      }

      // saving
      const toAddData: any[] = [];

      for (const mark of markData.value) {
        const originalMark = originalMarkData.value.find(
          (m) =>
            m.student == mark.student &&
            ((!m.divided && m.subject == mark.subject) ||
              (m.divided && m.subsubject == mark.subsubject))
        );
        if (originalMark) {
          if (Number(mark.mark) != Number(originalMark.mark))
            await ApiService.patch("/bulletin/v2/mark/" + originalMark._id, {
              data: {
                mark: mark.mark,
              },
            })
              .then()
              .catch((err) => console.log(err));
        } else {
          const obj: VMarkData = {
            divided: mark.divided,
            student: mark.student,
            mark: mark.mark,
            trimester: Number(targetData.value.trimestre),
            classroom: String(route.params.id),
            subject: mark.subject,
            type: "one",
          };
          if (mark.divided) obj.subsubject = mark.subsubject;
          toAddData.push(obj);
        }
      }

      await ApiService.put("/bulletin/v2/mark/many", {
        data: toAddData,
      })
        .then()
        .catch((err) => console.log(err));

      for (const originalMark of originalMarkData.value) {
        const mark = markData.value.find(
          (m) =>
            m.student == originalMark.student &&
            ((!m.divided && m.subject == originalMark.subject) ||
              (m.divided && m.subsubject == originalMark.subsubject))
        );
        if (!mark)
          await ApiService.delete("/bulletin/v2/mark/" + originalMark._id)
            .then()
            .catch((err) => console.log(err));
      }

      saveLoading.value = false;
      setUnsavedChanges(false);
      Swal.fire({
        text: t("notes.savedAll"),
        icon: "success",
        buttonsStyling: false,
        confirmButtonText: "Ok!",
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });

      updateMarks();
    };

    watch(
      targetData,
      () => {
        updateMarks();
      },
      { deep: true }
    );
    const key = ref(route.params.type);
    //when route.params.type change to need to init the refs (no refrech)
    function initialize(newType) {
      activeModule.value = null;
      divided.value = false;
      selectedSubject.value = "";
      selectedSubjectName.value = "";
      setUnsavedChanges(false);
      targetData.value = {
        matiere: "",
        trimestre: trimester || "1",
      };
      // Place all your init functions here
      key.value = String(newType);
      getModules(currentLevel.value, subjectList.value);
      // ... add as many functions as you need
    }
    watch(
      () => route.params.type,
      (newType) => {
        initialize(newType);
      }
    );

    // search student
    const search = ref<string>("");
    const searchItems = () => {
      if (search.value !== "") {
        let results: Array<IStudent> = [];
        for (let j = 0; j < cacheStudentList.value.length; j++) {
          if (searchingFunc(cacheStudentList.value[j], search.value)) {
            results.push(cacheStudentList.value[j]);
          }
        }
        studentList.value = Object.assign([], results);
      } else studentList.value = Object.assign([], cacheStudentList.value);
    };

    const searchingFunc = (obj, value): boolean => {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (obj[key].indexOf(value) != -1) {
            return true;
          }
        }
      }
      return false;
    };
    return {
      t,
      disabled,
      targetData,
      studentList,
      onMounted,
      search,
      apiUrl,
      Check,
      Delete,
      saveAll,
      loading,
      updateMarks,
      originalMarkData,
      markData,
      listModules,
      handleSubjectChange,
      getMarkValue,
      selectedSubject,
      selectedSubjectName,
      activeModule,
      subsubjectData,
      divided,
      InfoFilled,
      deleteMark,
      getTotalSubsubjects,
      handleMarkChange,
      getMarkCssClasses,
      disabledAccess,
      key,
      searchItems,
      fromModule,
      saveLoading,
      title,
    };
  },
});
