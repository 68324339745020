// src/composables/useLeaveConfirmation.ts
import { ref } from 'vue';
import { ElMessageBox, ElMessage } from 'element-plus';
import { useRouter, NavigationGuardNext, RouteLocationNormalized } from 'vue-router';

export function useLeaveConfirmation() {
  const hasUnsavedChanges = ref<boolean>(false); // Track unsaved changes
  const router = useRouter();

  // Function to set unsaved changes state
  function setUnsavedChanges(value: boolean) {
    hasUnsavedChanges.value = value;
  }

  // Function to show leave confirmation
  async function showLeaveConfirmation(): Promise<boolean> {
    if (!hasUnsavedChanges.value) return true; // No unsaved changes, proceed

    try {
      await ElMessageBox.confirm(
        'Êtes-vous sûr de vouloir quitter la page sans enregistrer les modifications ?',
        'Attention',
        {
          confirmButtonText: 'Oui',
          cancelButtonText: 'Annuler',
          type: 'warning',
        }
      );

      return true; // Allow navigation
    } catch {
      ElMessage({
        type: 'info',
        message: 'Veuillez enregistrer les modifications',
      });
      return false; // Prevent navigation
    }
  }

  // Router navigation guard
  function navigationGuard(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
    showLeaveConfirmation().then((proceed) => {
      if (proceed) {
        next(); // Allow navigation
      } else {
        next(false); // Cancel navigation
      }
    });
  }

  return {
    hasUnsavedChanges,
    setUnsavedChanges,
    navigationGuard,
  };
}
